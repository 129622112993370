<template>
  <div>
    <Nav :pt="isIndex" />
    <div
      style="
        font-size: 0.88rem;
        text-align: center;
        padding: 10px 0;
        margin-top: 60px;
      "
    >
      2025年苏州民族管弦乐团社会艺术水平考级报名表
    </div>
    <van-form @submit="onSubmit" label-width="100px">
      <div class="info-title">
        <span class="block"></span>
        <span class="text">考生个人信息</span>
      </div>
      <van-field
        required
        name="name"
        v-model="form.name"
        label="姓名"
        placeholder="请输入"
        clearable
        :rules="rules"
      >
      </van-field>
      <van-field
        required
        name="sex"
        label="性别"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group
            v-model="form.sex"
            direction="horizontal"
            @change="saveRadioChange('sex')"
          >
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        required
        name="pinyin"
        v-model="form.pinyin"
        label="拼音"
        placeholder="请输入"
        clearable
        :rules="rules"
      >
      </van-field>
      <van-field
        required
        name="nation"
        v-model="form.nation"
        label="民族"
        placeholder="请输入"
        :rules="rules"
        clearable
      >
      </van-field>
      <van-field
        required
        readonly
        name="birthDateTimeText"
        :value="form.birthDateTimeText"
        label="出生日期"
        placeholder="请选择"
        clickable
        :rules="rules"
        @click="openCalendar"
      ></van-field>
      <van-popup v-model="showCalendar" position="bottom">
        <van-datetime-picker
          v-model="form.birthDateTime"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onDateConfirm"
          color="#0088FE"
          @cancel="showCalendar = false"
        />
      </van-popup>
      <van-field
        required
        readonly
        name="documentTypeText"
        :value="form.documentTypeText"
        label="证件类型"
        placeholder="请选择"
        clickable
        :rules="rules"
        @click="showdocumentTypePicker = true"
      ></van-field>
      <van-popup v-model="showdocumentTypePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="documentTypeOptions"
          @confirm="onPickerConfirm($event, 'documentType', 'name')"
          @cancel="showdocumentTypePicker = false"
          value-key="name"
        ></van-picker>
      </van-popup>
      <van-field
        required
        name="idNumber"
        v-model="form.idNumber"
        label="证件号码"
        placeholder="请输入"
        clearable
        :rules="[
          { required: true, validator: validator, message: '请输入正确内容' },
        ]"
      >
      </van-field>
      <!-- <van-field
        required
        name="isCertificate"
        label="乐理证书"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group
            v-model="form.isCertificate"
            direction="horizontal"
            @change="certificateChange"
          >
            <van-radio name="1">有</van-radio>
            <van-radio name="0">无</van-radio>
          </van-radio-group>
        </template>
      </van-field> -->
      <!-- <van-field
        required
        name="certificateNumber"
        v-model="form.certificateNumber"
        label="证书编号"
        placeholder="请输入"
        clearable
        :rules="rules"
        v-if="certificateNumberShow"
      >
      </van-field> -->
      <van-field
        required
        name="isTakeTheExam"
        label="是否参加乐理考试"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group
            v-model="form.isTakeTheExam"
            direction="horizontal"
            @change="saveRadioChange('isTakeTheExam')"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        required
        name="parentName"
        v-model="form.parentName"
        label="家长姓名"
        placeholder="请输入"
        clearable
        :rules="rules"
      >
      </van-field>
      <van-field
        required
        name="parentPhone"
        v-model="form.parentPhone"
        label="家长手机号"
        placeholder="请输入"
        clearable
        type="tel"
        :rules="[
          {
            required: true,
            validator: validatorPhone,
            message: '请输入正确内容',
          },
        ]"
      >
      </van-field>
      <van-field
        required
        name="parentEmail"
        v-model="form.parentEmail"
        label="电子邮箱"
        placeholder="请输入"
        clearable
        :rules="[{ validator: validatorEmail, message: '请输入正确内容' }]"
      >
      </van-field>
      <van-field
        required
        name="address"
        v-model="form.address"
        label="通讯地址"
        placeholder="请输入"
        clearable
        :rules="rules"
      >
      </van-field>
      <van-field
        required
        name="registrationAgencyName"
        v-model="form.registrationAgencyName"
        label="报名机构名称"
        placeholder="请输入"
        clearable
        :rules="rules"
      >
      </van-field>
      <van-field
        required
        name="teacherName"
        v-model="form.teacherName"
        label="教师姓名"
        placeholder="请输入"
        clearable
        :rules="rules"
      >
      </van-field>
      <van-field
        required
        name="teacherPhone"
        v-model="form.teacherPhone"
        label="教师手机号"
        placeholder="请输入"
        clearable
        type="tel"
        :rules="[{ validator: validatorPhone, message: '请输入正确内容' }]"
      >
      </van-field>
      <van-field required name="idPhoto" label="一寸证件照（蓝底、白底均可）">
        <template #input>
          <simple-cropper
            :initParam="uploadParam"
            :successCallback="uploadHandle"
            ref="cropper"
            v-if="!idPhotoStr"
          >
            <div class="van-uploader__upload" @click="upload">
              <i
                class="van-icon van-icon-photograph van-uploader__upload-icon"
              ></i>
            </div>
          </simple-cropper>
          <div class="van-uploader__preview" v-else>
            <div
              class="van-image van-uploader__preview-image"
              @click="viewPhoto(idPhotoStr)"
            >
              <img
                :src="idPhotoStr"
                class="van-image__img"
                style="object-fit: cover"
              />
            </div>
            <div class="van-uploader__preview-delete" @click="deletIDPhoto">
              <i
                class="
                  van-icon van-icon-cross
                  van-uploader__preview-delete-icon
                "
              ></i>
            </div>
          </div>
        </template>
      </van-field>
      <van-field
        required
        label="乐理证书复印件"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <van-uploader
            v-model="form.certificateStr"
            :max-count="1"
            :after-read="certificateAfterRead"
          ></van-uploader>
        </template>
      </van-field>
      <van-field
        required
        label="户口本或身份证复印件"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <van-uploader
            v-model="form.idCopyStr"
            :max-count="1"
            :after-read="idCopyAfterRead"
          ></van-uploader>
        </template>
      </van-field>
      <div class="info-title">
        <span class="block"></span>
        <span class="text">报考科目信息</span>
      </div>
      <van-field
        required
        name="examSubjectIdText"
        :value="form.examSubjectIdText"
        label="报考科目"
        placeholder="请选择"
        clickable
        :rules="rules"
        readonly
        @click="showexamSubjectIdPicker = true"
      ></van-field>
      <van-popup v-model="showexamSubjectIdPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="examSubjectIdOptions"
          @confirm="onPickerConfirm($event, 'examSubjectId', 'subject')"
          @cancel="showexamSubjectIdPicker = false"
          value-key="subject"
        ></van-picker>
      </van-popup>
      <van-field
        required
        name="examLevelIdText"
        :value="form.examLevelIdText"
        label="报考级别"
        placeholder="请选择"
        clickable
        :rules="rules"
        readonly
        @click="openPicker('examLevelId')"
      ></van-field>
      <van-popup v-model="showexamLevelIdPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="levelOptions"
          @confirm="onPickerConfirm($event, 'examLevelId', 'level')"
          @cancel="showexamLevelIdPicker = false"
          value-key="level"
        ></van-picker>
      </van-popup>
      <!-- <van-field
        required
        name="songCategory"
        v-model="form.songCategory"
        rows="2"
        autosize
        label="报考曲目"
        type="textarea"
        placeholder="请输入报考曲目"
        show-word-limit
        :rules="rules"
      ></van-field> -->
      <van-field
        required
        name="examLevelSongCategoryIdText"
        :value="form.examLevelSongCategoryIdText"
        label="报考曲目"
        placeholder="请选择"
        clickable
        :rules="rules"
        readonly
        @click="openPicker('examLevelSongCategoryId')"
      ></van-field>
      <van-popup v-model="showexamLevelSongCategoryIdPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="examLevelSongCategoryIdOptions"
          @confirm="
            onPickerConfirm($event, 'examLevelSongCategoryId', 'songCategory')
          "
          @cancel="showexamLevelSongCategoryIdPicker = false"
          value-key="songCategory"
        ></van-picker>
      </van-popup>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          :disabled="loading"
          native-type="submit"
          :loading="loading"
          loading-text="加载中..."
          >提交
        </van-button>
      </div>
    </van-form>
    <Footer />
  </div>
</template>
<script>
import { wexinPay } from "@/wxpay";
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import SimpleCropper from "@/components/SimpleCropper";
import {
  Button,
  Form,
  Field,
  Uploader,
  RadioGroup,
  Radio,
  DatetimePicker,
  Popup,
  Picker,
  ImagePreview,
  Dialog,
} from "vant";
export default {
  data() {
    return {
      isIndex: false,
      showCalendar: false, // 出生日期选择
      showdocumentTypePicker: false, // 证件类型选择
      documentTypeOptions: [
        { name: "身份证", id: 1 },
        { name: "港澳台身份证/护照/台胞证及其他", id: 2 },
      ],
      certificateNumberShow: false, // 证书编号
      examSubjectIdOptions: [], // 报考科目
      showexamSubjectIdPicker: false, // 报考科目选择
      levelOptions: [], // 报考级别
      showexamLevelIdPicker: false, // 报考级别选择
      examLevelSongCategoryIdOptions: [], // 报考曲目
      showexamLevelSongCategoryIdPicker: false, // 报考曲目选择
      minDate: new Date(1900, 1, 1),
      maxDate: new Date(),
      form: {
        // 提交最终数据表单
        birthDateTime: new Date(),
      },
      idPhotoStr: "",
      rules: [
        {
          required: true,
        },
      ],
      isEditMode: false, // 修改模式
      loading: false, // 加载
      uploadParam: {
        fileType: "recruit", // 其他上传参数
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
      },
    };
  },
  components: {
    "van-button": Button,
    "van-form": Form,
    "van-field": Field,
    "van-uploader": Uploader,
    "van-radio-group": RadioGroup,
    "van-radio": Radio,
    "van-datetime-picker": DatetimePicker,
    "van-popup": Popup,
    "van-picker": Picker,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Dialog.Component.name]: Dialog.Component,
    SimpleCropper,
    Nav,
    Footer,
  },
  mounted() {
    // 获取jscode
    this.handleJsCode();
    // 获取科目
    this.getExamSubject();
  },
  methods: {
    // 获取科目
    getExamSubject() {
      this.$ajax
        .get(this.$ajax.localOrOnline() + "examRegistration/getExamSubject")
        .then((res) => {
          this.examSubjectIdOptions = res.data || [];
        })
        .catch((err) => {
          this.$toast.fail(err.message);
        });
    },
    // 上传证件照
    upload() {
      this.$refs["cropper"].upload();
    },
    // 上传证件照成功回调
    uploadHandle(fileName, imgSrc) {
      this.form.idPhotoOldFileName = fileName;
      this.idPhotoStr = imgSrc;
    },
    // 删除证件照
    deletIDPhoto() {
      this.idPhotoStr = "";
    },
    // 查看照片
    viewPhoto() {
      ImagePreview({
        images: [this.idPhotoStr],
        closeable: false,
      });
    },
    // 详情数据处理
    handleDetailData(data) {
      this.form = data;
    },
    // radio change
    saveRadioChange(type) {
      this.form[type] = this.form[type] + "";
    },
    // 身份证验证
    validator(val) {
      const card =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (this.form.documentType == 1) {
        return card.test(val);
      } else {
        return true;
      }
    },
    // 手机号验证
    validatorPhone(val) {
      const phone =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      return phone.test(val);
    },
    // 邮箱验证
    validatorEmail(val) {
      const email = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      return email.test(val);
    },
    // 下拉选择
    openPicker(type) {
      let flag = true;
      if (type == "examLevelId") {
        // 验证上级是否选择
        if (!this.form.examSubjectId) {
          this.$toast.fail("请您先选择报考科目");
          flag = false;
        }
      }
      if (flag) {
        this["show" + type + "Picker"] = true;
      }
    },
    // 下拉选择确认
    onPickerConfirm(value, type, text) {
      this.form[type] = value.id;
      this.form[type + "Text"] = value[text];
      this["show" + type + "Picker"] = false;
      if (type == "examSubjectId") {
        // 根据不同科目切换不同级别
        this.levelOptions = [];
        this.$ajax
          .get(this.$ajax.localOrOnline() + "examRegistration/getExamLevel", {
            examSubjectId: this.form.examSubjectId,
          })
          .then((res) => {
            this.levelOptions = res.data || [];
          })
          .catch((err) => {
            this.$toast.fail(err.message);
          });
      } else if (type == "examLevelId") {
        // 根据不同级别切换不同曲目
        this.$ajax
          .get(
            this.$ajax.localOrOnline() +
              "examRegistration/getExamLevelSongCategory",
            {
              examLevelId: this.form.examLevelId,
            }
          )
          .then((res) => {
            this.examLevelSongCategoryIdOptions = res.data || [];
          })
          .catch((err) => {
            this.$toast.fail(err.message);
          });
      }
    },
    // 时间对象转格式化
    formatDate(date) {
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate();
      return date.getFullYear() + "-" + M + "-" + D;
    },
    // 时间戳转格式化
    getFormatDate(datetime) {
      let date = new Date(datetime * 1000);
      let y = date.getFullYear();
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate();
      let result = y + "-" + M + "-" + D;
      // 返回
      return result;
    },
    // 格式化转时间戳
    getUnixTime(date) {
      let dateTime = new Date(date);
      let timeStr = dateTime.getTime().toString();
      return timeStr.substr(0, 10);
    },
    // 选择是否有证书
    certificateChange(val) {
      if (val == 1) {
        this.certificateNumberShow = true;
      } else {
        this.form.certificateNumber = "";
        this.certificateNumberShow = false;
      }
      this.saveRadioChange("isCertificate");
    },
    // 选择出生日期
    openCalendar() {
      this.showCalendar = true;
      if (this.form.birthDateTimeText) {
        this.form.birthDateTime = new Date(this.form.birthDateTimeText);
      } else {
        this.form.birthDateTime = new Date();
      }
    },
    // 时间选择确认
    onDateConfirm(date) {
      this.form.birthDateTime = Date.parse(date) / 1000;
      this.form.birthDateTimeText = this.formatDate(date);
      this.showCalendar = false;
    },
    // 身份证或户口本上传
    idCopyAfterRead(data) {
      this.form.idCopyStr = [{ url: data.content, isImage: true }];
      this.form.idCopy = data.file;
    },
    // 乐理证书上传
    certificateAfterRead(data) {
      this.form.certificateStr = [{ url: data.content, isImage: true }];
      this.form.certificate = data.file;
    },
    // 微信支付
    wxPayment(signUpData) {
      this.payNo = signUpData.payNo;
      let self = this;
      this.$ajax
        .get(this.$ajax.localOrOnline() + "registeryFee/pay/wx", {
          payNo: signUpData.payNo,
          token: this.token,
        })
        .then((payRes) => {
          this.loading = false;
          self.handleWXpayData(payRes.data);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 处理调起微信支付的数据
    handleWXpayData(val) {
      console.log(1234, JSON.stringify(val));
      let obj = {
        appId: val.appId,
        timeStamp: val.timeStamp,
        nonceStr: val.nonceStr,
        signature: val.signature,
        packages: val.wxPackage,
        paySign: val.sign,
        signType: val.signType,
      };
      let _this = this;
      wexinPay(obj)
        .then((res) => {
          console.log(JSON.stringify(res), "支付成功");
          _this.$router.push({ name: "RegSuccess" });
        })
        .catch((err) => {
          console.log(JSON.stringify(err), "支付失败");
          _this.$dialog
            .alert({
              title: "提示",
              message: "支付失败",
            })
            .then(() => {});
        });
    },
    onSubmit() {
      if (!this.idPhotoStr) {
        this.$toast.fail("请上传证件照");
        return;
      }
      this.$dialog
        .confirm({
          title: "提示",
          message: "请确认信息填写无误,即将跳转支付",
        })
        .then(() => {
          this.loading = true;
          // 最终提交的数据
          this.form.idPhoto = this.idPhotoStr.split(";base64,")[1];
          this.form.birthDateTime = this.form.birthDateTimeText;
          var data = new FormData();
          for (const key in this.form) {
            data.append(key, this.form[key]);
          }
          var configs = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          this.$ajax
            .post(this.$ajax.localOrOnline() + "examRegistration/registration", data, configs)
            .then((res) => {
              if (res.code == 200) {
                let signUpData = res.data;
                // 拉起支付
                this.wxPayment(signUpData);
              } else {
                this.loading = false;
                this.$toast.fail(res.message);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$toast.fail(err.message);
            });
        })
        .catch(() => {});
    },
    // 获取jscode并登录
    handleJsCode() {
      if (window.location.href.split("?").length > 1) {
        let arr1 = window.location.href.split("?")[1];
        if (arr1.length > 1) {
          let str1 = arr1.split("&")[0];
          let code = str1.split("=")[1];
          this.jsCode = code;
          // 获取token
          this.$ajax
            .get(this.$ajax.localOrOnline() + "silentLogin", {
              jsCode: this.jsCode,
            })
            .then((res) => {
              if (res.code == 200) {
                this.token = res.data;
              } else if (res.code == 1000) {
                // 微信获取code
                this.wxAuthorize();
              } else {
                this.$dialog
                  .alert({
                    title: "提示",
                    message: res.message,
                  })
                  .then(() => {});
              }
            })
            .catch(() => {});
        }
      } else {
        // 微信获取code
        this.wxAuthorize();
      }
    },
    // 微信获取code
    wxAuthorize() {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        "wx5629af8d8cfe6927" +
        "&redirect_uri=" +
        "https%3A%2F%2Fh5.suzhousco.com%2F%23%2Ftraining%2FtestRegistration" +
        "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
    },
  },
};
</script>
<style scoped>
.info-title {
  padding: 10px;
  background: #fff;
  margin: 10px 0;
}

.block,
.text {
  display: inline-block;
  vertical-align: middle;
}

.block {
  width: 5px;
  height: 20px;
  background: #1989fa;
}

.text {
  margin-left: 5px;
  color: #666;
  font-size: 14px;
}
.avatar-uploader {
  background: red !important;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
}
.el-icon-plus {
  width: 150px;
  height: 150px;
  font-size: 30px;
}
</style>